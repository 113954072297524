import { FC, useCallback, useMemo, useState } from 'react';
import { Box } from '@mui/material';
import { Svg } from 'modules/theme/components/svg/Svg';
import { Input } from 'modules/theme/components/fields/input/Input';
import SearchIcon from 'modules/theme/components/icons/basic/Search.svg';
import CloseIcon from 'modules/theme/components/icons/basic/Close.svg';
import {
    LocationFullscreen
} from 'components/advertisementsFilter/components/common/mobileFilter/partials/filterDialog/partials/content/partials/location/partials/content/partials/locationFullscreen/LocationFullscreen';
import {
    Autocomplete
} from 'components/advertisementsFilter/components/common/common/location/partials/autocomplete/Autocomplete';
import {
    CompleteBox
} from 'components/advertisementsFilter/components/common/common/location/partials/completeBox/CompleteBox';
import { useLocationVisibility } from 'components/advertisementsFilter/hooks/location/visibility/useLocationVisibility';
import {
    usePreviouslySearchedLocations
} from 'components/advertisementsFilter/hooks/location/previouslySearched/usePreviouslySearchedLocations';
import {
    IAutocompleteOption
} from 'components/advertisementsFilter/interfaces/location/option/IAutoCompleteOption';
import { ILocation } from 'modules/api/clients/location/interfaces/location/ILocation';

interface ILocationContent {
    locationQuery: string;
    selectedLocation: ILocation;
    optionsInitial: ILocation[];
    onChangeLocationQuery: (value: string) => void;
    onChangeLocation: (location: ILocation) => void;
    autocompleteLocationOptions: IAutocompleteOption[];
}

export const LocationContent: FC<ILocationContent> = ({
    locationQuery,
    optionsInitial,
    onChangeLocation,
    selectedLocation,
    onChangeLocationQuery,
    autocompleteLocationOptions,
}) => {
    const [isLocationFullscreenOpened, setIsLocationFullscreenOpened] = useState(false);

    const onFullscreenToggle = useCallback((flag: boolean) => {
        setIsLocationFullscreenOpened(flag);
    }, []);

    const {
        options: previouslySearchedOptions,
    } = usePreviouslySearchedLocations();

    const optionsMobile = useMemo(() => {
        return previouslySearchedOptions.slice(0, 3);
    }, [previouslySearchedOptions]);

    const {
        showBaseLocations,
        showPreviouslySearchedLocations,
    } = useLocationVisibility(
        locationQuery,
        selectedLocation,
        autocompleteLocationOptions,
        previouslySearchedOptions,
    );

    const renderLastSearchedAutocomplete = !!showPreviouslySearchedLocations;
    const renderCompleteBox = !renderLastSearchedAutocomplete && showBaseLocations;

    const contentComponent = renderLastSearchedAutocomplete
        ? <Autocomplete
            isLastSearched
            options={optionsMobile}
            title='Naposledy ste hľadali:'
            onChangeLocation={onChangeLocation}
        />
        : renderCompleteBox
            ? <CompleteBox
                options={optionsInitial}
                onChangeLocation={onChangeLocation}
                selectedLocationId={selectedLocation && selectedLocation.id}
            />
            : <></>;

    return <Box
        display='flex'
        flexDirection='column'
    >
        <Box
            onClick={() => {
                onFullscreenToggle(true);
            }}
            mb={renderCompleteBox ? 2: 0}
        >
            <Input
                value={locationQuery}
                endIconStopPropagation
                startIcon={SearchIcon}
                placeholder='Kde hľadáte?'
                endIcon={locationQuery && (() => <Svg
                    icon
                    width={14}
                    component={CloseIcon}
                    onIconClick={() => {
                        onChangeLocationQuery('');
                        onChangeLocation(null);
                    }}
                />)}
                onChange={(value) => {
                    if (!isLocationFullscreenOpened) {
                        onFullscreenToggle(true);
                    }

                    onChangeLocationQuery(value);
                }}
            />
        </Box>

        {contentComponent}

        {isLocationFullscreenOpened &&
            <LocationFullscreen
                locationQuery={locationQuery}
                onChangeLocation={onChangeLocation}
                onFullscreenToggle={onFullscreenToggle}
                onChangeLocationQuery={onChangeLocationQuery}
                isFullscreenOpened={isLocationFullscreenOpened}
                autocompleteLocationOptions={autocompleteLocationOptions}
            />
        }
    </Box>;
};